import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function NotificationModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>수업 안내</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        안녕하세요 드림필라테스 입니다
        <br />
        <br />
        다음주 월요일 10월 14일부터 11월 1일 금요일까지
        <br />
        <br />
        월요일 저녁 5시 30분 /
        <br />
        금요일 저녁 6시, 7시 김수란 강사님
        <br />
        화요일 목요일 저녁 6시 오수인 강사님
        <br />
        <br />
        그룹수업이 추가 개설되어 안내 드립니다
        <br />
        예약하실 때 참고하세요!
      </Modal.Body>

      {/* <Modal.Body>
        <div className="NotificationModal">
          <img src="https://storage.googleapis.com/storage.cocore.co.kr/images/Dream_noti_20240111.jpeg" />
        </div>
      </Modal.Body> */}

      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NotificationModal;
